.center-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Fredoka One', cursive;
  }
.onboard {
    height: 100vh;
    background-color: #393d72;
  }  
  .input {
    padding: 10px;
    font-size: 16px;
    margin: 10px;
    background-color: antiquewhite;
  }
 
  
  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ff4880;
  }
  
  .circle i {
    font-size: 2em;
    color: white;
  }
  .h2 {
    color: white;
    text-align: center;
  }
  .h3 {
    color: white;
    text-align: center;
  }