/* Container */
.language-toggle {
    position: relative;
    display: inline-block; /* So the dropdown is positioned relative to this container */
  }
  
  /* Toggle Button */
  .language-toggle__button {
    cursor: pointer;
    font-family: 'Fredoka One', cursive;
    font-size: 18px;
    background-color: #393d72;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    outline: none;
  
    transition: background-color 0.2s ease;
  }
  
  .language-toggle__button:hover,
  .language-toggle__button:focus {
    background-color: #2f3260;
  }
  
  /* Dropdown Menu */
  .language-toggle__menu {
    list-style: none;
    padding: 0;
    margin: 8px 0 0 0;
    position: absolute;
    min-width: 100px;
    background-color: #393d72;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  
    /* Positioning: you can tweak for your layout */
    top: 100%;  /* Directly below the button */
    left: 0;
  
    z-index: 999; /* So it appears above other elements */
  }
  
  /* Individual Menu Items */
  .language-toggle__menu-item {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
  
    transition: background-color 0.2s ease;
  }
  
  .language-toggle__menu-item:hover {
    background-color: #2f3260;
  }
  
  .language-toggle__flag {
    width: 20px;
    height: 15px;
    margin-right: 8px;
    border-radius: 2px; /* Slight rounding on the flag edges */
  }
  
  /* Responsive Design (optional) */
  @media (max-width: 576px) {
    .language-toggle__button {
      font-size: 16px;
      padding: 6px 10px;
    }
  
    .language-toggle__menu-item {
      padding: 6px 10px;
    }
  }
  