.input-styles {
  margin-top: 10px;
}
.dialog-title-component {
    text-align: center; 
}
.dialog-title-style {
    font-size: 30px;
    font-size: 100 px;
    font-family: 'Fredoka One', cursive;
    color: rgb(57, 61, 114);
}
.dialog-subtitle-style {
    font-size: 20px;
    font-family: 'Fredoka One', cursive;
    text-align: center;
}
