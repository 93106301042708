.image-card {
    width: 400px;
    height: 500px;
    overflow: hidden;
    border-radius: 10px;
    transition: transform 0.3s ease;
    margin-top: 20px;
    margin-bottom: 30px;
}

.image-card img {
    width: 100%;
    height: 100%;
}

.image-card--enlarged {
    transform: scale(1.2);
}
